<template>      
    <layout>
                   
            <!-- <v-card
                style="position:fixed; top:0"
                flat
                color="grey lighten-5"
                class="pl-6"
            >
                <div class="d-flex align-center primary--text text-caption font-weight-light ">
                    <v-img
                        alt="Vuetify Logo"
                        class="shrink mr-2"
                        contain
                        src="../../assets/logo.png"
                        transition="scale-transition"
                        width="30"
                    />
                    <span
                        class="d-none d-md-flex"
                    >
                        {{ schoolName }}
                    </span>
                </div>
            </v-card> -->

            <!-- <v-card
                style="position:fixed; right:40px"
                flat
                color="grey lighten-5"
                class="mt-3"
            >
                <div class="d-flex align-center primary--text text-caption font-weight-light ">                    
                    {{ user.name }}
                </div>
            </v-card> -->

            
        <!-- <v-card
            width="150"
            id="speed-dial"
            flat
            color="grey lighten-5"
            class="d-none mt-10 d-md-flex flex-column pt-2"
        >
            <v-speed-dial
                v-model="fab"                        
                direction="bottom"
                transition="slide-y-reverse-transition"
                class="d-none d-md-flex align-start"
            >
                <template
                    v-slot:activator
                >
                    <v-fab-transition>
                        <v-btn
                            v-show="!hidden"
                            v-model="fab"
                            color="primary"
                            fab
                        >
                            <v-icon v-if="fab">
                                mdi-close
                            </v-icon>
                            <v-icon v-else>
                                mdi-menu
                            </v-icon>
                        </v-btn>
                    </v-fab-transition>    
                </template>

                <v-btn
                    v-for="(item, index) in navigationMenus"
                    :key="index"                            
                    small
                    color="primary"
                    text
                    class="ml-4 pa-0"
                    :to="item.route"                            
                >
                    <v-avatar 
                        color="primary"
                        size="24"
                        class="mr-2"
                    >
                        <v-icon 
                            small 
                            color="white"                                   
                        >
                            {{ item.icon }}
                        </v-icon>
                    </v-avatar>
                    {{ item.title }}
                </v-btn>

            </v-speed-dial>
            <div
                v-if="!fab"
                class="caption mt-3 d-flex ml-3"
                style="position:relative"
            >
                Menu
            </div>
        </v-card> -->

        <slot></slot>
                
        <v-navigation-drawer
            absolute
            permanent
            right
            class="mr-4 mt-10 d-none d-md-flex"
            height="288"
            width="200"
            style="top:64px"
        >
            <v-list class="grey lighten-5 py-0">
                <v-list-item
                    v-for="(item, index) in menuItems"
                    :key="index"
                    :to="item.route"
                    color="primary"                        
                    active-class="active"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
                
        <v-bottom-navigation
            background-color="primary"
            height="48"
            absolute
            class="d-flex d-md-none"
        >
            <bottom-navigation></bottom-navigation>
        </v-bottom-navigation>
                      
        
    </layout>    
</template>

<script>
import { mapGetters } from 'vuex';
import BottomNavigation from '../FormProgress';
import Layout from '../layouts/App';

export default {
    components: {
        BottomNavigation,
        Layout
    },

    created: function () {
        this.initialize()
    },

    data: () => ({
        menuItems: [
            {title: "Student Data", route: '/registration/student-data' },
            {title: "Family Data", route: '/registration/family-data'},
            {title: "Medical Data", route: '/registration/health-data'},
            {title: "Other Data", route: '/registration/other-data'},
            {title: "Upload Documents", route: '/registration/documents'},
            {title: "Complete", route: '/registration/complete'},
        ],
        navigationMenus: [
            {title: "Dashboard", icon: "mdi-view-dashboard", route: '/dashboard'},
            {title: "Term Reports", icon: "mdi-file-certificate", route: '/term-reports'},
            {title: "Logout", icon: "mdi-logout", route: '/logout'},
        ],
        
        fab: false,
        hidden: true,
    }),

    

    computed: {
        ...mapGetters({
            schoolName: 'form/getSchoolName',
            user: 'auth/getUser',
        }),

        activeFab () {
          if(this.documentTop){
              return {color: this.primaryColor + ' ' + this.colorDarken, icon: 'mdi-chevron-down'};
          }
          else{
              return {color: this.primaryColor + ' ' + this.colorDarken, icon: 'mdi-chevron-up'}
          }
      },
    },

    methods: {
        quickScroll(){
          this.documentTop = !this.documentTop;
          if(this.documentTop){
              window.scrollTo(0, document.body.scrollHeight);
          }
      },

      initialize () {
          this.hidden = false;
      }
    }
}
</script>

<style scoped>
    ::v-deep .v-list .v-list-item {
        border-left: 2px solid #e5e5ee;
    }

    ::v-deep .v-list-item.v-list-item--link.theme--light{
        border-left: 0px;
    }

    ::v-deep .v-list .v-list-item--active {
        border-left-color: #2159b0;
    }

    ::v-deep #speed-dial {
        position: fixed;
        top: 64px;
    }

    ::v-deep .v-speed-dial__list{
        align-items: start;
    }    
    
</style>