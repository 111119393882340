import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: sessionStorage.authenticated || false,
        admin: sessionStorage.admin || false,
        userID: sessionStorage.userID || null,
        user: {},        
        userRecord: null,
        studentRecord: null,
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        newPassword: '',
        displayPasswordChanged: false,
        validStudentID: false,
        step: 1,        
        passwordHint: '',
        passwordPlaceholder: '',
        apiToken: sessionStorage.getItem('apiToken') || null,
        accessToken: sessionStorage.getItem('access_token') || null,          
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUserID (state) {
            return state.userID
        },      

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },

        getStudentRecord(state){
            return state.studentRecord
        },

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },

        getStudentIDValidation(state){
            return state.validStudentID
        },

        getStep(state){
            return state.step
        },
        
        getPasswordHint(state){
            return state.passwordHint
        },

        getPasswordPlaceholder(state){
            return state.passwordPlaceholder
        },           

        getAdmin(state){
            return state.admin
        },

        getUser (state) {
            return state.user
        },

        getApiToken (state) {
            return state.apiToken
        },

        getAccessToken (state) {
            return state.accessToken
        }
    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUserID (state, value) {
            state.userID = value
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },

        setStudentRecord(state, value){
            state.studentRecord = value
        },

        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },

        setStudentIDValidation(state, value){
            state.validStudentID = value
        },

        setStep(state, value){
            state.step = value
        },

        setPasswordHint(state, value){
            state.passwordHint = value
        },

        setPasswordPlaceholder(state, value){
            state.passwordPlaceholder = value
        },

        setAdmin(state, value){
            state.admin = value
        },

        setUser (state, value) {
            state.user = value
        },

        setApiToken (state, value) {
            state.apiToken = value
        },

        setAccessToken (state, value) {
            state.accessToken = value
        }
    },

    actions: {
        async signIn ({ commit }, credentials){                      
            commit('setOverlay', true); 
            try {
                await axios.get('/sanctum/csrf-cookie');
                const { data: { user, token } } = await axios.post('/api/login-student', credentials);
                
                commit('setAuthenticated', true);
                commit('setUserID', user.student_id);
                commit('setLoginErrors', '');
                commit('setApiToken', user.api_token);
                commit('setAccessToken', token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                
                sessionStorage.setItem('authenticated', user.name);                
                sessionStorage.setItem('userID', user.student_id);
                sessionStorage.setItem('apiToken', user.api_token);
                sessionStorage.setItem('access_token', token);

                if(user.name == 'Admin'){
                    commit('setAdmin', true);
                    sessionStorage.setItem('admin', user.name)
                }

                if(user.password_reset == 1){
                    commit('setResetPassword', true);
                    commit('setPasswordHint', 'yyyymmdd');
                    commit('setPasswordPlaceholder', 'Enter date of birth');  
                }
                else{
                    commit('setResetPassword', false);
                    commit('setPasswordHint', '');
                    commit('setPasswordPlaceholder', 'Enter password')                         
                } 


                // if(dataLogin.name == 'Admin'){
                //     commit('setAdmin', true);
                //     sessionStorage.admin = dataLogin.name;
                //     console.log('admin signed in')
                // }
                
                
            } catch (error) {                
                if(error.response && !error.response.data.errors){
                    console.log(error.response);
                    commit('setLoginErrors', error.response.data.message); 
                }
                else if(error.response && error.response.data.errors){
                    commit('setLoginErrors', error.response.data.errors.message[0])
                }                
                else console.log(error);

                commit('setAuthenticated', false);
                commit('setOverlay', false);  
            } 
        },

        async adminSignIn({ commit, dispatch }, credentials){
            commit('setOverlay', true);
            try{
                await axios.get('/sanctum/csrf-cookie');
                const { data: { user, token } } = await axios.post('/api/admin-login', credentials);
                sessionStorage.authenticated = "1";
                sessionStorage.admin = "1";
                commit('setLoginErrors', '')
                commit('setAuthenticated', true);
                commit('setUser', credentials);
                commit('setOverlay', false);
                commit('setUserID', user.student_id);
                commit('setLoginErrors', '');
                commit('setApiToken', user.api_token);
                commit('setAccessToken', token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            }
            catch(error){
                dispatch('serverError', error)
                if(error.response) console.log(error.response);
                else console.log(error);
            }                  
        },

        async getStudent({ commit, getters}){  
            const url = '/api/student-record/' + getters.getUserID
            + '/' + getters.getApiToken;
            // console.log(url);
            // return axios.get(url, {
            //     headers: {
            //         Authorization: `Bearer ${getters.getApiToken}`
            //     }
            // })
            return axios.get(url)
                .then(response => {
                    // console.log(response);
                    commit('setStudentRecord', response.data[0]);
                    let userInitials = response.data[0].first_name.charAt(0)+response.data[0].last_name.charAt(0);
                    let userName = response.data[0].first_name+' '+response.data[0].last_name;
                    commit('setUser', {
                        student_id: response.data[0].id,
                        name: userName,
                        initials: userInitials
                    })
                    commit('setOverlay', false);
                })
                .catch(error => {                    
                    commit('setUser', null)
                    console.log(error)
                })
                
        },       

        async changePassword({ getters, commit }){
            commit('setOverlay', true);
            let url = '/api/change-password-student';
            return await axios.post(url, {
                "student_id" : getters.getUser.student_id,
                "password" : getters.getNewPassword,
            });                   
        },        

        async signOut({commit}){
            try {
                await axios.post('api/logout');
            } catch (error) {
                if(error.response) console.log(error.response);
                console.log(error);                
            }
            commit('setAuthenticated', false); 
            sessionStorage.clear();  
        },
        
        async saveRecord({getters}){
            let studentRecord = getters.getStudentRecord;            
            return axios.post('/api/student', studentRecord);
        },

       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },

    //    async getStudents(){
    //        return await axios.get('/api/students')
    //    },

       async resetPassword({ commit, getters }, resetPassword){
            commit('setOverlay', true);
            await axios.post('/api/change-password-student',{
                "student_id" : getters.getUser.id,
                "reset_password" : resetPassword,
            });
            commit('setResetPassword', resetPassword);
            commit('setDisplayPasswordChanged', true);
            commit('setOverlay', false);
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       },

       serverError({ commit }, err ){
            var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'errors');                                     
            if( hasErrors && err.response.data.errors.message.length != 0){
                commit('setLoginErrors', err.response.data.errors.message[0]);
            }
            else{
                commit('setLoginErrors', err.response.statusText);
            }                    
            commit('setAuthenticated', false);
            commit('setOverlay', false);
       }
    }
}