<template>
    <v-app>    
       <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
            <v-container 
                class="fill-height justify-center" 
                fluid

            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col cols="12">
                        <v-expand-transition>
                            <v-card 
                                width="400"
                                v-show="expand"
                                class="elevation-12 mx-auto pa-4"
                            >                            
                                <v-row
                                    justify="center"
                                >
                                    <v-img
                                        src="../../assets/logo.png"
                                        max-width="100"
                                        contain
                                        class="d-flex"
                                    ></v-img>
                                </v-row>
                                <v-row no-gutters> 
                                    <v-col cols="12">
                                        <div 
                                            class="pa-4"
                                            v-if="getloginErrors"    
                                        >
                                            <v-alert                                                    
                                                type="error"
                                                outlined
                                                class="red--text"
                                            >
                                                {{ getloginErrors }}
                                            </v-alert>    
                                        </div>                                       
                                        
                                        <div class="text-center font-weight-light primary--text">Welcome to</div>
                                        <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>        
                                        <v-card-text>
                                            <div :class="title_css">Admin Sign in</div>                                            
                                        </v-card-text>

                                        <v-form 
                                            class="pa-5"
                                            @submit.prevent
                                        >
                                            <v-text-field
                                                label="Username"
                                                placeholder="Enter username"
                                                name="id"                                                
                                                type="text"
                                                color="primary"
                                                v-model="formData.name"                                                
                                                autofocus                                                        
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-account
                                                </v-icon>
                                            </v-text-field> 

                                            <v-text-field
                                                v-show="password"
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show ? 'text' : 'password'"
                                                :label="passwordLabel"
                                                :placeholder="passwordPlaceholder"
                                                :hint="passwordHint"
                                                persistent-hint
                                                @click:append="show = !show"                                                    
                                                name="password"
                                                v-model="formData.password"                                                
                                                v-on:keyup="keyPress"                                                        
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-key
                                                </v-icon>
                                            </v-text-field>
                                        
                                    
                                            <div class="text-right mt-6">                                                
                                                <v-btn 
                                                    color="primary" 
                                                    @click="submit" 
                                                    dark
                                                    block
                                                >LOGIN</v-btn>
                                            </div>                                               
                                
                                            
                                        </v-form>
                                       
                                    </v-col>                                
                                </v-row>
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="getOverlay"
                    opacity="0.8"
                    z-index="10"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
                
            </v-container>            
        </v-main> 
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'AdminLogin',   
    data(){
        return {
            step: 1,
            show: false,
            userpassword: '',
            formData: {
                name: '',
                password: '',                
            },
            password: true,           
            passwordPlaceholder: 'Enter password',
            passwordLabel: 'Password',
            passwordHint: '',
            windowTitle: 'Enter Password',
            resetPasswordErrors: '',
            overlay: false, 
            expand: true,                     
        }
    },         
    props: {
        source: String,
    },
    watch: {
        userpassword: {
            handler(val){
                this.formData.student_id = val;                
            }
        },              
    },
    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'form/getSchoolName',
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
            getloginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',
            getUser: 'auth/getUser',                   
        }),
        title_css(){
            return  'primary--text text-h5 text-center ';
        },      
    },
    methods: {
        ...mapActions({
            signIn: 'auth/adminSignIn',
        }),
        ...mapMutations({
            openDialog: 'auth/setDialog',            
            setUserId: 'auth/setUserId',            
            setUser: 'form/setUser',
            loginError: 'auth/setLoginErrors',            
            //getUser: 'auth/getUser',
        }),
        nextStep(e){
            if(e.keyCode === 13){
                //console.log('Enter was pressed');
                this.loginError('');
                this.step++;
            }
        },
        keyPress (e) {
            // console.log('key press');
            if(e.keyCode === 13){
                this.submit();
            }
        },   
        async submit () {
            await this.signIn(this.formData);            
            if(this.getAuthenticated){                              
                this.setUser = this.getUser.name[0];
                //console.log(this.getUser.name[0]);
                this.$router.replace('/admin/dashboard');
            }                                 
        },
        enterPassword(){
            //console.log(this.formData);
            if(this.formData.name){
                this.loginError('');
                this.step++;
            }
            else{
                this.loginError('Username field empty');
            }
        }        
        
    }
}
</script>

<style scoped>
    .container-background{
        background-image: url('../../assets/background.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }    

</style>